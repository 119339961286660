import {Dispatch, SetStateAction, useEffect, useState} from "react";
import rollItems from "../../../assets/roll_items.png"
import closePopup from "../../../assets/close_popup.svg"
import styles from "./css/prize.module.css"
import ApiClient from "../../../api/basic";
import { PrizeItemType } from "../../../types/prize/prizeItem";
import { Item } from "../home/components/item";
import Fireworks from "react-canvas-confetti/dist/presets/fireworks";
import Crossfire from "react-canvas-confetti/dist/presets/crossfire";
import Snow from "react-canvas-confetti/dist/presets/snow";
import Realistic from "react-canvas-confetti/dist/presets/realistic";
import Explosion from "react-canvas-confetti/dist/presets/explosion";
import Pride from "react-canvas-confetti/dist/presets/pride";
import useAccountStore from "../../../store/account";
import { BeltFull } from "../../components/popUp/beltFull/beltFull";
import { toast } from "react-toastify";

export function PrizeMain({setIsOpen, isOpen, canClose, setCanClose, closePopUp}: 
    {setIsOpen: Dispatch<SetStateAction<boolean>>,
         isOpen: boolean,
          canClose: boolean,
           setCanClose: Dispatch<SetStateAction<boolean>>,
           closePopUp: () => void}) {
    const [mlSectionMainItems, setMLSectionItems] = useState(423)
    const [isOpenBelt, setIsOpenBelt] = useState(false)
    const [prizes, setPrizes] = useState<Record<number, PrizeItemType>>()
    const [winIndex, setWinIndex] = useState<number>()
    let [endRoll, setEndRoll] = useState(false)
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 450);
    let [showEffects, setShowEffects] = useState(false)
    let [winItemName, setWinItemName] = useState("")
    const accountStore = useAccountStore();
    const apiClient = new ApiClient();
    const isStart = false


    async function wait(ms: number) {
        return new Promise(resolve => {
            setTimeout(resolve, ms);
        });
    }

    async function loadPrizes() {
        try {
            let resp = await apiClient.spinWheel();
            if (!resp.success) {
                throw new Error(`Ошибка загрузки призов: ${resp}`);
            } else {
                setPrizes(resp.data.prizes);
                setWinIndex(resp.data.winIndex);
                return { prizes: resp.data.prizes, winIndex: resp.data.winIndex, showBeltFull: resp.data.showBeltFull };
            }
        } catch (error) {
            throw new Error(`Ошибка загрузки призов: ${error}`);
        }
    }

    // Функция для воспроизведения звука
    function playAudio(nameFile: string) {
        const audio = new Audio(`/sounds/${nameFile}`);
        audio.volume = 0.3;
        audio.play();
        return audio
    }

    
    async function scrollItem(currentMargin: number, startSpeed: number, endSpeed: number) {
        console.log(isMobileView)
        let sizeItem = isMobileView ? 170 : 312;
        const endMargin = currentMargin - sizeItem;
        const number_shifts = isMobileView ? 16 : 30; // Количество шагов
        let current_shifts = 0;

        // Линейная интерполяция для расчета скорости на каждом шаге
        while (current_shifts < number_shifts) {
            currentMargin -= 10; // Уменьшение отступа
            current_shifts += 1;
            
            // Пропорциональное изменение скорости: чем больше шагов пройдено, тем ближе скорость к конечной
            let currentSpeed = startSpeed + ((endSpeed - startSpeed) * (current_shifts / number_shifts));
    
            // Обновляем стиль элемента в каждом шаге
            setMLSectionItems(currentMargin);
    
            // Ждем перед следующим шагом с текущей скоростью
            await wait(currentSpeed);
        }
        
        setMLSectionItems(endMargin);
        return endMargin;
    }
    
    

    async function scrollItems({ _prizes, _winIndex, _showBeltFull }: { _prizes: Record<number, PrizeItemType>, _winIndex: number, _showBeltFull: boolean }) {
        if (!_prizes) {
            alert("Ваша сессия просрочена. Авторизуйтесь перед началом розыгрыша")
            setIsOpen(false)
            return
        }

        await wait(1000);
        
        let max_speed = 5; // Максимальная начальная скорость (меньшая задержка = выше скорость)
        let min_speed = 20; // Минимальная конечная скорость (большая задержка = медленнее)
    
        let currentMargin = mlSectionMainItems; // Начальный отступ
        if (window.innerWidth < 426){
            if (_winIndex !== 0){
                currentMargin = 105
            }
            else{
                currentMargin = 105
                setMLSectionItems(currentMargin)
            }
        }
    
        let need_to_spin = _winIndex; // Количество вращений
        let current_index = 0;
        // Диапазон изменения скорости
        let speedRange = min_speed - max_speed;
        // console.log(_prizes)
        let winItem = _prizes[_winIndex];
    
        while (current_index < need_to_spin) {
            // Прокрутка с текущей скоростью
            let interpolationFactor = current_index / need_to_spin;
            let speed = max_speed + (speedRange * interpolationFactor); // Плавное замедление
            playAudio("wheel_round_item.mp3")
            currentMargin = await scrollItem(currentMargin, speed, speed);
    
            // Увеличиваем индекс текущего вращения
            // let currentItem = _prizes[current_index]; 
            current_index += 1;
        }
        setEndRoll(true);
        // ts-ignore
        apiClient.me().then((resp) => {
            if (!resp.success){
              console.log(`Error load user: ${resp}`)
            }
            accountStore.setUser(resp.data.user)
            accountStore.setIsAuth(true)
        }).catch((error) => {
            console.log(`Error loading prizes: ${error}`)
        })
        
        setWinItemName(winItem.name);
        if (winItem.sound){
            playAudio(winItem.sound)
        }
        setShowEffects(true);
        await wait(3000);
        if (_showBeltFull){
            setIsOpenBelt(true)
        }
        await wait(3000);
        setShowEffects(false);
        console.log('Прокрутка завершена');
        
        setCanClose(true)
    }
    
    useEffect(() => {
        function handleResize() {
            setIsMobileView(window.innerWidth < 426);
        }

        handleResize(); // Проверка при загрузке
        window.addEventListener("resize", handleResize); // Отслеживание изменения размера

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    
    useEffect(() => {
        playAudio("blow_to_paw.mp3")
        loadPrizes().then(({ prizes, winIndex, showBeltFull }) => {
            scrollItems({_prizes: prizes, _winIndex: winIndex, _showBeltFull: showBeltFull});
        }).catch((error) => {
            toast.error(`Приобретите подписку`);
            setIsOpen(false);
        });
    }, [isStart]);
    return (
        <>
        <section className={styles.section_main}>
            <img src={closePopup} alt="closePopup" onClick={closePopUp} className={styles.closePopup}/>
            <h1 className={styles.section_main_h1}>{endRoll ? 'Вы выиграли!' : 'Идет розыгрыш приза!'}</h1>
            <div className={styles.section_main_scroll_wrap}>
                <img src={rollItems} alt="rollItems" className={styles.rollItems_img}/>
                <div className={`${styles.section_main_items_wrap}`} style={{marginLeft: `${mlSectionMainItems}px`}}>
                    {prizes && Object.keys(prizes).map((key) => {
                        // @ts-ignore
                        let item = prizes[key]
                        return (<Item key={key} {...item} isLocked={false} size="large"/>)
                        })}
                </div>
            </div>
            <div className={styles.section_main_wait_prize}>
                <p className={styles.section_main_wait_prize_p}>{endRoll ? `УРА!!! Вы выиграили` : 'Подождите немного, идет розыгрыш приза...'}</p>
                {endRoll && <p className={styles.section_main_wait_prize_p_win}>&nbsp;{winItemName}</p>}
                {showEffects && <Crossfire autorun={{ speed: 15 }} />}
                {showEffects && <Realistic autorun={{ speed: 0.3 }} />}
            </div>
        </section>
        <BeltFull isOpen={isOpenBelt} setIsOpen={setIsOpenBelt}/>
        </>
    )
}
import React, { useEffect } from 'react';
import ApiClient from '../../../../api/basic';
import useAccountStore from '../../../../store/account';

function TelegramWidget() {
    const apiClient = new ApiClient();
    const accountStore = useAccountStore();

    const botName = 'ILuckyPunchI_bot'


    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://telegram.org/js/telegram-widget.js?22";
        script.async = true;
        script.setAttribute('data-telegram-login', botName);
        script.setAttribute('data-size', 'large');
        script.setAttribute('data-onauth', 'onTelegramAuth(user)');
        script.setAttribute('data-request-access', 'write');

        // @ts-ignore
        document.getElementById('telegram-widget').appendChild(script);

        // @ts-ignore
        window.onTelegramAuth = function (user) {
            apiClient
                .auth(user)
                .then((response) => {
                    if (!response.success) {
                        console.log(`Error auth user: ${response}`);
                        alert(`Error auth user: ${response}`);
                        return;
                    }
                    accountStore.setIsAuth(true);
                    accountStore.setTelegramUser(response.user);
                    window.location.reload();
                })
                .catch((error) => {
                    console.log(`Error auth user: ${error}`);
                    alert(`Error auth user: ${error}`);
                });
        };
    }, [botName]);

    return <div id="telegram-widget"></div>;
}

export default TelegramWidget;
